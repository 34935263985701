import { ButtonArrow } from './ButtonArrow';
import { useButtonArrowResizer } from './useButtonArrowResizer';
import { VanDeKlokButtonLinkProperties } from './ButtonInterfaces';
import cx from 'classnames';
import Link from 'next/link';
import React, { useRef } from 'react';
import styles from './Button.module.css';

export const VanDeKlokButtonLink = ({
  children,
  reverse = false,
  theme,
  target,
  ...rest
}: VanDeKlokButtonLinkProperties) => {
  const innerReference = useRef<HTMLAnchorElement>(null);
  const svgReference = useRef<SVGSVGElement>(null);

  useButtonArrowResizer(innerReference, svgReference);

  return (
    <Link className={getClassNames(theme, reverse)} target={target} {...rest}>
      <span ref={innerReference} className={styles.inner}>
        {children}
      </span>
      <ButtonArrow ref={svgReference} />
    </Link>
  );
};

const getClassNames = (theme: VanDeKlokButtonLinkProperties['theme'], reverse: boolean) =>
  cx(styles.sharedStyles, styles.vanDeKlok, {
    [styles.outline]: theme === 'outline',
    [styles.outlineInverted]: theme === 'outlineInverted',
    [styles.reverse]: reverse,
    [styles.orange]: theme === 'orange',
  });
